// src/components/Chargers.js
import React from 'react';
import '../styles/Chargers.css';
import chargerBg from '../assets/images/Charger-bg.png'; // Assuming you have this background image
import chargers from '../assets/images/chargers.jpg'; // Assuming you have a relevant image
import icon1 from '../assets/icons/auto.png'; // Assuming you have these icons
import icon2 from '../assets/icons/car.png';
import icon3 from '../assets/icons/bus.png';
import icon4 from '../assets/icons/truck.png';
import icon5 from '../assets/icons/van.png';

const Chargers = () => {
  return (
    <section id="products" className="section chargers-section" style={{ backgroundImage: `url(${chargerBg})` }}>
      <div className="chargers-content">
        <h2 className="chargers-title">CHARGERS</h2>
        <p>For different types of vehicles</p>
        <p>We have a variety of AC and DC stations, which are capable of charging various types of electric vehicles.</p>
        <div className="chargers-icons">
          <div className="charger-icon">
            <div className="icon-circle">
              <img src={icon1} alt="Icon for vehicle type 1" />
            </div>
            <p>Autos</p>
          </div>
          <div className="charger-icon">
            <div className="icon-circle">
              <img src={icon2} alt="Icon for vehicle type 2" />
            </div>
            <p>Cars</p>
          </div>
          <div className="charger-icon">
            <div className="icon-circle">
              <img src={icon3} alt="Icon for vehicle type 3" />
            </div>
            <p>Vans</p>
          </div>
          <div className="charger-icon">
            <div className="icon-circle">
              <img src={icon4} alt="Icon for vehicle type 4" />
            </div>
            <p>Trucks</p>
          </div>
          <div className="charger-icon">
            <div className="icon-circle">
              <img src={icon5} alt="Icon for vehicle type 5" />
            </div>
            <p>Buses</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chargers;
