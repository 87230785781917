// src/components/Connect.js

import React from 'react';
import '../styles/Section.css';
import connectImage from '../assets/images/connect-bg.png'; // Correctly import the image

const Connect = () => {
  return (
    <section id="connect" className="section">
      <h2>Connect</h2>
      <img src={connectImage} alt="Connect" />
    </section>
  );
};

export default Connect;
