// src/components/VisionMission.js
import React from 'react';
import '../styles/VisionMission.css';
import visionMissionImage from '../assets/images/vision-mission.png'; // Assuming you have a relevant image

const VisionMission = () => {
  return (
    <section id="vision-mission" className="section vision-mission-section" style={{ backgroundImage: `url(${visionMissionImage})` }}>
      <div className="vision-mission-content">
        <div className="text-section">
          <h2>VISION</h2>
          <p>
            At QCX, our vision is to drive the world towards a greener, more sustainable future. 
            We envision a world where electric vehicles are the norm, supported by an extensive, accessible, and eco-friendly charging infrastructure. 
            Our goal is to be a catalyst in this transformative era of automotive history.
          </p>
        </div>
        <div className="text-section">
          <h2>MISSION</h2>
          <p>
            At QCX, our mission is to drive the world towards a greener, more sustainable future. 
            We envision a world where electric vehicles are the norm, by an extensive, accessible, and eco-friendly charging infrastructure. 
            Our goal is to be a catalyst in this transformative era of automotive history.
          </p>
        </div>
      </div>
    </section>
  );
};

export default VisionMission;
