// src/components/AboutUs.js
import React from 'react';
import '../styles/AboutUs.css';
import aboutUsImage from '../assets/images/about-us.png'; // Assuming you have a relevant image

const AboutUs = () => {
  return (
    <section id="about-us" className="section about-us-section">
      <div className="about-us-content">
        <h2>ABOUT US</h2>
        <p>
          At QCX, our journey began with a shared passion for sustainable innovation and a drive to redefine the future of mobility in India. 
          Founded by Sumukh Dahale and Pratik Desai, two visionaries determined to make a positive impact, QCX emerged from a simple yet powerful 
          idea to empower communities and businesses with cutting-edge electric vehicle infrastructure and renewable energy solutions.
        </p>
        <div className="about-us-image">
          <img src={aboutUsImage} alt="About Us" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
