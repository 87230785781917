// src/components/Header.js

import React from 'react';
import '../styles/Header.css';
import logo from '../assets/images/logo.png';
import logoQ from '../assets/images/logo-q.png';
import background from '../assets/images/background.png'; // Ensure this path is correct

const Header = () => {
  return (
    <header className="header" style={{ backgroundImage: `url(${background})` }}>
      <div className="top-section">
        <div className="logo-container">
          <img src={logoQ} alt="QCX Secondary Logo" className="logo" />
          <img src={logo} alt="QCX Energy Pvt.Ltd Logo" className="logo" />
        </div>
        <nav className="navigation">
          <ul className="nav-links">
            <li><a href="#about-us">About us</a></li>
            <li><a href="#products">Products</a></li>
            <li><a href="#contact-us">Contact us</a></li>
            <li><a href="#partner-with-us">Partner with us</a></li>
          </ul>
        </nav>
      </div>
      <div className="centered-logos">
        <img src={logoQ} alt="QCX Secondary Logo" className="centered-logo" />
        <img src={logo} alt="QCX Energy Pvt.Ltd Logo" className="centered-logo" />
      </div>
    </header>
  );
};

export default Header;
