// src/App.js

import React from 'react';
import './styles/App.css';
import Header from './components/Header';
import Chargers from './components/Chargers';
import AboutUs from './components/AboutUs';
import VisionMission from './components/VisionMission';
import Connect from './components/Connect';

function App() {
  return (
    <div className="App">
      <Header />
      <Chargers />
      <AboutUs />
      <VisionMission />
      <Connect />
    </div>
  );
}

export default App;
